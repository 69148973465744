@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html * {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  transition: 100ms;
  max-width: 97vw;
}

.project-slide-container {
  height: 480px;
  max-width: 100v;
}

.project-slider {
  max-height: 480px;
  max-width: 100vw;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.reactColor:hover {
  color: #61dafb;
}

.html5Color:hover {
  color: #e34c26;
}

.css3Color:hover {
  color: #264de4;
}
.jsColor:hover {
  color: #f0db4f;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #232323;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #232323;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipText {
  position: absolute;
  color: white;
  visibility: hidden;
  z-index: 1;
  background: rgba(35, 35, 35, 0.9);
}

.tooltipTarget:hover .tooltipText {
  visibility: visible;
}

p {
  max-width: 65ch;
}

.work-list-item:hover .work-img,
.project-slide:hover .project-slide-img {
  opacity: 1;
}

.work-list-item:hover .work-list-item-title,
.project-slide:hover .work-list-item-title {
  color: rgb(109 40 217);
}

.work-img,
.project-slide-img {
  opacity: 0.7;
  transition: 500ms;
}

.work-img:hover,
.project-slide-img:hover {
  opacity: 1;
}

.work-list-item:hover .work-img {
  transform: scale(1.05);
}
